import {
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import React from 'react';
import Logo from '../assets/businessAreaIcons/WTRW_Logo.png';

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: 'center',
        marginTop: 40,
        paddingLeft: '15%',
        paddingRight: '15%',
        flexWrap: "wrap",
        wordWrap: true,
        overflowX: "auto"
    },
    imageBorder: {
        borderRadius: '10%',
        width: '100%',
        heght: '100%',
        maxHeight: 150,
        minHeight: 55,
        maxWidth: 150,
        minWidth: 55,
    },
    title: {
        fontWeight: 'bold'
    },
    subtitle: {
        marginTop: 10
    },
    button: {
        marginTop: 10,
        background: theme.palette.success.dark,
        width: 120,
        height: 35,
        borderRadius: 5,
        borderColor: theme.palette.success.dark,
        color: '#ffffff'
    },
    footer: {
        fontSize: 12,
        bottom: 0,
        position: 'fixed',
        fontfamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400
    }
}));

function Download() {
    const classes = useStyles();
    return (
        <Grid container className={classes.container}>
            <Grid item xs={8}>
                <Typography className={classes.title} variant='h4'>Win the Right Way</Typography>

                <Typography className={classes.subtitle} variant="body2">Click the install button to download the Win the Right Way App on your Android device. If you have questions or technical difficulties, please contact WTRW_App@cat.com</Typography>
                <a href={process.env.REACT_APP_DOWNLOAD_URL} download target="_blank" rel="noopener noreferrer">
                    <div>
                        <button className={classes.button}>Install</button>
                    </div>
                </a>
            </Grid>
            <Grid item xs={4}>
                <img className={classes.imageBorder} alt="Not found" src={Logo} maxWidth={150} maxHeight={150} minWidth={55} minHeight={55}  ></img>
            </Grid>
            <Grid item xs={4}>
            <div>
                <Typography className={classes.footer}>&#169; 2025 Copyright: WTRW | App Version: 1.2.8</Typography>
            </div>
            </Grid>
        </Grid>
    );
}

export default Download;